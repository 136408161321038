import React from "react";
import Head from "next/head";
import getConfig from "next/config";
import useRouter from "components/hooks/useRouter";
import Script from "next/script";

const { publicRuntimeConfig } = getConfig();

export default function WebsiteHead() {
    const hasGTM = publicRuntimeConfig.GTM_ID;
    const isProd = publicRuntimeConfig.ENV === "production";
    const { locales, asPath, defaultLocale } = useRouter();

    return (
        <>
            <Head>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="icon" type="image/png" href="/favicons/favicon.ico"/>
                <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/favicons/apple-touch-icon-57x57.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/favicons/apple-touch-icon-114x114.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/favicons/apple-touch-icon-72x72.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/favicons/apple-touch-icon-144x144.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/favicons/apple-touch-icon-60x60.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/favicons/apple-touch-icon-120x120.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/favicons/apple-touch-icon-76x76.png"/>
                <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/favicons/apple-touch-icon-152x152.png"/>
                <meta name="application-name" content="&nbsp;"/>
                <meta name="msapplication-TileColor" content="#FFFFFF"/>
                <meta name="msapplication-TileImage" content="/favicons/mstile-144x144.png"/>
                <meta name="msapplication-square70x70logo" content="/favicons/mstile-70x70.png"/>
                <meta name="msapplication-square150x150logo" content="/favicons/mstile-150x150.png"/>
                <meta name="msapplication-square310x310logo" content="/favicons/mstile-310x310.png"/>

                <link rel="alternate" href={`${publicRuntimeConfig.BASE_URL}${asPath}`} hrefLang={"x-default"}/>

                {locales.map((locale) => {
                    return <link
                        key={locale}
                        rel="alternate"
                        href={`${publicRuntimeConfig.BASE_URL}/${locale}${asPath}`}
                        hrefLang={locale}
                    />;
                })}

                {hasGTM && (
                    <script dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];     w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}');`,
                    }}
                    />
                )}
            </Head>
            <Script type="text/javascript" src={"//js.hsforms.net/forms/v2.js"} defer/>
            <script>
                window.dataLayer = window.dataLayer || [];
            </script>
            {hasGTM && (
                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                    }}
                />
            )}
        </>
    );
}
import "assets/index.sass";
import { ToastProvider } from "react-toast-notifications";
import { CookiesProvider } from "react-cookie";
import WebsiteHead from "components/core/WebsiteHead";
import { appWithTranslation } from "next-i18next";

function Website({ Component, pageProps }) {
    return (
        <CookiesProvider>
            <ToastProvider autoDismiss={true}>
                <WebsiteHead/>
                <Component {...pageProps} />
            </ToastProvider>
        </CookiesProvider>
    );
}

export default appWithTranslation(Website);